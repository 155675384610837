.heading {
  color: $c-brand;

  &--h1 {
    font-size: 35px;
    font-weight: $fw-bold;
  }

  &--h2 {
    font-size: 25px;
    font-weight: $fw-bold;
  }
}
