.overlay {
  $small-width: 535px;
  $large-width: 1175px;

  @include full();

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $z-overlay;

  &__backdrop {
    @include full();

    background: rgba($c-grey, 0.8);
  }

  &__layer {
    z-index: 1;
    position: relative;
  }

  &__close {
    margin: 0 !important;
    width: 45px;
    height: 45px;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;

    &:hover,
    &:focus {
      outline: 0 none;
      color: $c-grey;
    }
  }

  &--small {
    .overlay__layer {
      background: $c-white;
      padding: 25px 50px;
      box-shadow: $s-dreamy;
      width: $small-width;
    }
  }

  &--large {
    .overlay__layer {
      background: $c-white;
      padding: 25px 50px;
      box-shadow: $s-dreamy;
      width: $large-width;
    }
  }

  &--note {
    .overlay__close {
      right: 15px;
    }
  }
}
