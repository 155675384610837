.safe {
  display: flex;
  justify-content: space-between;

  &__notes {
    width: 40%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 40px 22px;
  }

  &__safe {
    width: 22%;
  }
}
