.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__grow {
    flex: 1;
    min-height: 0;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    margin-right: 50px;
    align-items: center;
  }

  &--full {
    .container__actions {
      margin-right: 0;
    }
  }

  &--block {
    align-items: flex-start;
  }
}
