.text {
  &--small {
    font-size: 16px;
    //font-weight: $fw-light;
    line-height: 1.7;
  }

  &--large {
    font-size: 25px;
  }
}
