.window {
  @include full();

  z-index: $z-window;
  background: $c-white;
  overflow: hidden;
  padding: 50px 50px 75px;

  &__head {
    margin-bottom: 30px;

    > * + * {
      margin-top: 26px;
    }
  }

  &__exit {
    padding: 10px;
    display: flex;
    align-items: center;
    color: $c-brand;
    font-weight: $fw-light;

    &-label {
    }

    &-icon {
      height: 20px;
      width: 20px;
      margin-left: 5px;
    }

    &:hover,
    &:focus {
      outline: 0 none;
      color: darken($c-brand, 20%);
    }
  }
}
