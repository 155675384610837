.bubble {
  $c-bg: $c-mauve;
  $triangle-width: 44px;
  $triangle-height: 18px;
  $offset: 10px;

  background: $c-bg;
  width: 385px;
  box-shadow: $s-short;

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: $triangle-height ($triangle-width / 2) 0 ($triangle-width / 2);
    border-color: $c-bg transparent transparent transparent;
    position: absolute;
    top: 100%;
    right: 0;
    filter: drop-shadow(0 0 3px rgba($c-black, 0.6));
  }

  &__close {
    width: 30px;
    height: 30px;
    padding: 5px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    &:hover,
    &:focus {
      outline: 0 none;
      background: mix($c-white, $c-mauve, 50%);
    }
  }

  &__content {
    background: $c-bg;
    padding: 30px;
    position: relative;
  }

  &-wrap {
    position: relative;

    > .bubble {
      z-index: $z-bubble;
      position: absolute;
      bottom: calc(100% + #{$triangle-height + $offset});
      right: calc(50% - #{$triangle-width / 2});
    }

    > .bubble--on-top {
      z-index: $z-ui-top;
    }

    // inverted
    &--left,
    .ui--bottom-left & {
      > .bubble {
        right: auto;
        left: calc(50% - #{$triangle-width / 2});

        &:before {
          right: auto;
          left: 0;
        }
      }
    }

    &--on-top {
      z-index: $z-ui-top;
    }
  }

  &--on-top {
    z-index: $z-ui-top;
  }
}
