@use "sass:color";

@keyframes visibility {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes visibility2 {
  0% {
    opacity: 0;
  }
  21% {
    opacity: 0;
  }
  22% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes visibility3 {
  0% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  44% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.button {
  background: $c-brand;
  color: $c-white;
  font-weight: $fw-bold;
  padding: 16px 14px;
  border-radius: 100px;
  line-height: 1;
  display: inline-block;
  text-decoration: none;

  &:before {
    content: '<';
    margin-right: 8px;
    visibility: hidden;
  }

  &:after {
    content: '>';
    margin-left: 8px;
    visibility: hidden;
  }

  &:hover,
  &:focus {
    outline: 0 none;
    background: $c-brand;

    &:after {
      visibility: visible;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    background: $c-brand;
    opacity: 0.6;
  }

  &--loading {
    .dot1 {
      animation: visibility 3s linear infinite;
    }
    .dot2 {
      animation: visibility2 3s linear infinite;
    }
    .dot3 {
      animation: visibility3 3s linear infinite;
    }
  }

  &--outlined {
    padding: 15px 10px;
    border: 1px solid $c-brand;

    background: $c-white;
    color: $c-brand;

    &:hover,
    &:focus {
      background: mix($c-white, $c-brand, 95%);
    }

    &:active {
      background: mix($c-white, $c-brand, 90%);
    }
  }
}
