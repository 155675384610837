:root {
  font: $fw-regular 100%/1.2 $ff-default;
  position: relative;
  color: $c-primary;
  background: $c-primary-bg;
  min-width: 320px;
}

html {
  height: 100%;
}

body {
  height: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: auto;
  overflow-y: scroll;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// ::selection {
//   color: $c-primary;
//   background: $c-yellow;
//   text-shadow: none;
// }
