.hints {
  text-align: center;
  font-size: 14px;
  font-weight: $fw-light;

  &__title {
    font-weight: $fw-bold;
    margin: 0 0 5px;
  }

  &__action {
    margin-top: 30px;
    font-size: 15px;

    .hints__hint + & {
      border-top: 1px solid $c-grey;
      margin-top: 15px;
      padding-top: 15px;
    }
  }

  &__outro {
    margin-top: 30px;
  }

  &__hint {
    text-align: left;

    & + & {
      border-top: 1px solid $c-grey;
      margin-top: 15px;
      padding-top: 15px;
    }
  }
}
