.hover-zoom {
  display: flex;
  height: 100%;

  &__full {
    background: $c-lightGrey;
    overflow: hidden;
    position: relative;
  }

  &__thumb {
    position: relative;
    cursor: crosshair;
  }

  &__area {
    display: none;
    position: absolute;
    background: $c-brand;
    opacity: 0.5;
    top: 0;
    left: 0;
    pointer-events: none;

    .hover-zoom__thumb:hover & {
      display: block;
    }
  }

  &__zoom {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__full-placeholder {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // basic styling for full component
  & &__full {
    flex: 1;
    margin-right: 30px;
  }

  & &__thumb {
    height: 100%;

    img {
      height: 100%;
    }
  }
}
