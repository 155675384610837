.flyer {
  .heading {
    margin-bottom: 24px;
  }

  &__overview {
    display: flex;
    padding-top: 30px;
    align-items: center;
    position: relative;
  }

  &__viewport {
    flex: 1;
    overflow: hidden;
    margin-left: -7px;
    margin-right: -7px;
  }

  &__slider {
    display: flex;
  }

  &__prev {
    margin-right: 20px;
    margin-bottom: 65px;
  }

  &__next {
    margin-left: 20px;
    margin-bottom: 65px;
  }

  &__item {
    flex: 0 0 20%;
    text-align: center;
    padding-right: 7px;
    padding-left: 7px;
  }

  &__item-thumb {
    vertical-align: bottom;
    margin-bottom: 15px;
    cursor: pointer;
    border: 1px solid $c-darkGrey;
    z-index: 1;
    position: relative;

    // HACKY! detect framer animation by selecting style
    &[style*='transform:'] {
      z-index: 2;
    }
  }

  &__placeholder {
    background: $c-lightGrey;
    border: 1px solid $c-lightGrey;
    margin-bottom: 15px;

    &:before {
      content: '';
      width: 100%;
      display: block;
      padding-top: (1076 / 752) * 100%;
    }
  }

  &__detail {
    display: flex;
  }

  &__thumb {
    height: 538px;

    // HACKY! detect framer animation by selecting style
    div[style*='transform:'] > & {
      pointer-events: none;
    }

    img {
      height: 100%;
    }
  }

  &__full {
    margin: 30px 0 20px;
    width: 100%;
  }

  &__detail-body {
    flex: 1;
    margin-right: 30px;
    height: auto;
  }
}
