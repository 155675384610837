.legal {
  font-size: 12px;
  padding: 10px 5px 0;
  display: flex;
  justify-content: flex-end;

  &__link {
    text-decoration: none;
    color: $c-darkGrey;

    &:hover {
      color: $c-brand;
    }

    & + & {
      margin-left: 15px;
    }
  }
}
