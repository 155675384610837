// PATHS
$font-path: '../../fonts/';
$img-path: '../../img';

// TYPO
$ff-default: 'BMS Humanity', -apple-system, BlinkMacSystemFont, avenir next,
  avenir, helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial,
  sans-serif;
$ff-cursive: 'Handlee', cursive;
$fw-light: 300;
$fw-regular: 400;
$fw-bold: 700;

// COLORS
$c-white: #fff;
$c-black: #595454;
$c-pitchblack: #000;
$c-darkGrey: #707070;
$c-grey: #a69f9f;
$c-lightGrey: #ebebeb;
$c-purple: #be2bbb;
$c-mauve: #eee7e7;
$c-red: #ba4422;

$c-primary: $c-black;
$c-primary-bg: $c-mauve;
$c-brand: $c-purple;

// SHADOWS
$s-dreamy: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
  0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
  0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);

$s-short: 0 0 4px rgba($c-black, 0.3), 0 2px 4px rgba($c-black, 0.15),
  0 4px 6px rgba($c-black, 0.15);

// DIMENSIONS
$d-min-width: 1280px;
$d-max-width: 1280px;
$d-reference-width: 1280px;
$d-reference-height: 720px;
$d-ratio: $d-reference-height / $d-reference-width;

// MEDIA QUERIES
$breakpoints: (
  min: $d-min-width,
  reference: $d-reference-width,
  max: $d-max-width
);

// Z-INDICES
$z-bubble: 5;
$z-ui-top: 15;
$z-window: 10;
$z-overlay: 20;
