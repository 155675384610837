@keyframes blinkingError {
  0% {
    color: $c-red;
  }
  49% {
    color: $c-red;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: $c-red;
  }
}

.keypad {
  $keypad-bg: #959498;
  $display-bg: #494949;
  $key-bg: #595454;
  $gap: 20px;
  $size: 60px;
  $padding: 30px;

  color: $c-white;
  text-align: center;
  width: $size * 3 + $gap * 2 + $padding * 2;

  &__body {
    background: $keypad-bg;
    padding: $padding;
    margin-bottom: $gap;
  }

  &__display,
  &__key {
    height: $size;
    border-radius: 3px;
  }

  &__display {
    margin-bottom: $gap;
    background: $display-bg;
    letter-spacing: 10px;
    font-size: 50px;
    line-height: $size + 5px;
  }

  &__keys {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: $gap;
    font-size: 36px;
  }

  &__key {
    background: $key-bg;
    box-shadow: inset 0px 0px 20px 0px #7a7a7a;

    &:hover {
      box-shadow: none;
    }

    &:focus {
      outline: 0 none;
    }

    &:active {
      background: darken($key-bg, 5%);
    }
  }

  &__actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: $gap;
    font-size: 14px;
  }

  &--error {
    .keypad__display {
      color: $c-red;
      background: mix($display-bg, $c-red, 80%);
      animation: blinkingError 1.2s infinite;
    }
  }
}
