.icon-button {
  $size: 45px;
  $padding: 10px;

  color: $c-darkGrey;
  display: flex;
  height: $size;
  border-radius: $size;
  align-items: center;
  position: relative;
  background: $c-mauve;

  // box shadow / bg on pseudo element so that badge is under shadow
  &:before {
    content: '';
    display: block;
    position: absolute;
    border-radius: $size;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: $s-short;
    background: $c-mauve;
    z-index: 2;
    transition: background ease-in-out 0.1s;
  }

  &:hover,
  &:focus {
    outline: 0 none;

    &:before {
      background: darken($c-mauve, 5%);
    }
  }

  &:active {
    &:before {
      background: darken($c-mauve, 10%);
      box-shadow: 0 0 4px rgba($c-black, 0.3);
    }
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;

    &:before {
      background: darken($c-mauve, 5%);
    }

    &:active {
      &:before {
        background: darken($c-mauve, 5%);
        box-shadow: $s-short;
      }
    }
  }

  &__icon {
    width: $size;
    height: $size;
    padding: $padding;
    z-index: 3;
  }

  &__label {
    z-index: 3;
    overflow: hidden;
    font-weight: $fw-light;

    &-inner {
      padding-right: $padding * 2;
      white-space: nowrap;
    }
  }

  &__badge {
    position: absolute;
    left: 100%;
    margin-left: -4px;
    background: $c-darkGrey;
    color: $c-white;
    z-index: 1;
    font-size: 12px;
    font-weight: $fw-bold;
    padding: 4px 6px 3px 8px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  // flip label to left side
  &--label-reversed {
    flex-direction: row-reverse;

    .icon-button__label {
      direction: rtl;
    }

    .icon-button__label-inner {
      padding-right: 0;
      padding-left: $padding * 2;
    }
  }

  // grey variant
  &--secondary {
    background: $c-grey;
    color: $c-white;

    &:before {
      background: $c-grey;
    }

    &:hover,
    &:focus {
      &:before {
        background: darken($c-grey, 5%);
      }
    }

    &:active {
      &:before {
        background: darken($c-grey, 10%);
      }
    }
  }

  &--on-top {
    z-index: $z-ui-top;
  }
}
