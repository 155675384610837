.welcome {
  width: 800px !important;

  &__enter {
    text-align: center;
  }

  &__logo {
    width: 285px;
  }

  > * + * {
    margin-top: 30px;
  }

  ul {
    padding-left: 0;
    list-style-position: inside;
  }

  svg {
    width: 20px;
    vertical-align: middle;
    margin: 0 2px;
  }
}
