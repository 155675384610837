.ui {
  $padding: 10px;
  $space-between: 40px;

  position: absolute;
  display: flex;

  > * + * {
    margin-left: $space-between;
  }

  &:empty {
    display: none;
  }

  &--top-left {
    top: 0;
    left: 0;
    padding: $padding 0 0 $padding;
  }

  &--top-center {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    padding: $padding 0 0 0;
  }

  &--top-right {
    top: 0;
    right: 0;
    padding: $padding $padding 0 0;
  }

  &--bottom-left {
    bottom: 0;
    left: 0;

    padding: 0 0 $padding $padding;
  }

  &--bottom-center {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0 0 $padding 0;
  }

  &--bottom-right {
    bottom: 0;
    right: 0;
    padding: 0 $padding $padding 0;
  }
}
