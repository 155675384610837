.couch {
  $dot-size: 18px;
  $line-width: 2px;
  $img-width: 385px;
  $img-height: 250px;

  &__drag {
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
  }

  &__actions {
    margin-top: 25px;
  }

  &__items {
    display: flex;
    height: 100%;
  }

  &__item {
    height: 100%;
    flex: 1;
    position: relative;
    cursor: grab;

    // dot
    &:after {
      @include center(true, false);

      content: '';
      display: block;
      bottom: 0;
      width: $dot-size;
      height: $dot-size;
      border-radius: $dot-size;
      background: $c-white;
      border: $line-width solid $c-black;
    }

    // line
    &:before {
      @include center(true, false);

      content: '';
      display: block;
      width: $line-width;
      top: 50%;
      bottom: 0;
      background: $c-black;
    }

    // dragging state
    &--dragging {
      cursor: grabbing;

      // dot
      &:after {
        background: $c-black;
        box-shadow: 0px 2px 4px rgba($c-black, 0.4);
      }
    }

    // error state
    .couch--error & {
      // dot
      &:after {
        background: $c-red;
        border-color: $c-red;
      }

      // line
      &:before {
        background: $c-red;
      }
    }

    // hover state
    &:hover {
      // line
      &:before {
        width: $line-width * 1.5;
      }
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: ($img-width / 2) * -1;
    height: $img-height;
    width: $img-width;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    pointer-events: none;

    .couch__item--1 & {
      background-image: url('#{$img-path}/couch/1.svg');

      .couch--hints & {
        background-image: url('#{$img-path}/couch/1-hint.svg');
      }
    }

    .couch__item--2 & {
      background-image: url('#{$img-path}/couch/2.svg');

      .couch--hints & {
        background-image: url('#{$img-path}/couch/2-hint.svg');
      }
    }

    .couch__item--3 & {
      background-image: url('#{$img-path}/couch/3.svg');

      .couch--hints & {
        background-image: url('#{$img-path}/couch/3-hint.svg');
      }
    }

    .couch__item--4 & {
      background-image: url('#{$img-path}/couch/4.svg');

      .couch--hints & {
        background-image: url('#{$img-path}/couch/4-hint.svg');
      }
    }
  }

  &__timeline {
    @include center(true, false);

    position: absolute;
    bottom: ($dot-size / 2) - ($line-width / 2);
    height: $line-width;
    background: $c-black;
    width: 85%;

    // start dot
    &:before {
      @include center(false, true);

      content: '';
      display: block;
      left: ($dot-size / 2) * -1;
      width: $dot-size;
      height: $dot-size;
      background: $c-black;
      border-radius: $dot-size;
    }

    // end arrow
    &:after {
      @include center(false, true);

      content: '';
      display: block;
      right: ($dot-size / 2) * -1;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: ($dot-size / 2) 0 ($dot-size / 2) ($dot-size);
      border-color: transparent transparent transparent $c-black;
    }

    .couch--error & {
      background: $c-red;

      &:before {
        background: $c-red;
      }

      &:after {
        border-color: transparent transparent transparent $c-red;
      }
    }
  }

  &--error {
  }
}
