.solved {
  &__images {
    display: flex;
    align-items: center;
  }

  &__image {
    width: auto;
    max-width: 60%;
    max-height: 100%;
  }

  &__item {
    width: auto;
    height: 70%;
    margin-left: 10%;
    max-width: 30%;
  }
}
