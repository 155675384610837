.stage {
  @include full();

  &--pointer,
  &--pointer > canvas {
    cursor: pointer !important;
  }

  &--demo {
    background: #e2ffc6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > * + * {
      margin-top: 10px;
    }
  }
}
