@mixin ratio($ratio: $d-ratio, $minHeight: false, $maxHeight: false) {
  position: relative;

  &:before {
    content: '';
    display: block;
    height: 0;
    padding-bottom: $ratio * 100%;
    pointer-events: none;
  }

  @if ($minHeight) {
    min-height: $minHeight;
  }

  @if ($maxHeight) {
    max-height: $maxHeight;
  }
}
