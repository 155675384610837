.note {
  $note-width: 645px;

  font-family: $ff-cursive;
  font-size: 22px;
  background: url('#{$img-path}/notes/bg.svg') center no-repeat;
  background-size: 100% 100%;
  padding: 45px 90px 45px 75px;
  line-height: 1.333;
  width: $note-width;

  p + p {
    margin-top: 20px;
  }
}
