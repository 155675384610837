.exit {
  text-align: center;

  &__logo {
    width: 285px;
  }

  &__text {
    font-size: 25px;
  }

  .heading {
    color: inherit;
  }

  > * + * {
    margin-top: 30px;
  }

  > * + .heading {
    margin-top: 50px;
  }
}
