.vases {
  height: 100%;
  position: relative;

  &__play {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }

  &__vases {
    height: 100%;
    text-align: center;
  }

  &__image {
    height: 100%;
    width: auto;
  }

  &__outline {
    fill: transparent;
    transition: fill ease-in-out 0.15s;
  }

  &__vase {
    cursor: url('#{$img-path}/vase/cursor.svg') 10 10,
      url('#{$img-path}/vase/cursor.png') 10 10, pointer;

    &:hover {
      .vases__outline {
        fill: rgba($c-brand, 0.3);
      }
    }

    &:active {
      cursor: url('#{$img-path}/vase/cursor.svg') 10 5,
        url('#{$img-path}/vase/cursor.png') 10 5, pointer;
    }

    &:active,
    &--playing {
      .vases__outline {
        fill: rgba($c-brand, 0.4);
      }
    }
  }
}
