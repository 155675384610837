@mixin font-face($filename, $ff, $fw, $fs: normal) {
  @font-face {
    font-family: $ff;
    font-weight: $fw;
    font-style: $fs;
    src: url('#{$font-path}/#{$filename}.eot');
    src: url('#{$font-path}/#{$filename}.eot?#iefix')
        format('embedded-opentype'),
      url('#{$font-path}/#{$filename}.woff2') format('woff2'),
      url('#{$font-path}/#{$filename}.woff') format('woff');
  }
}
